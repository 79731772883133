// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-modal-index-js": () => import("./../../../src/pages/components/Modal/index.js" /* webpackChunkName: "component---src-pages-components-modal-index-js" */),
  "component---src-pages-components-visitor-form-index-tsx": () => import("./../../../src/pages/components/VisitorForm/index.tsx" /* webpackChunkName: "component---src-pages-components-visitor-form-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

